import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faDownload } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class StorageProducts extends React.Component {
  render() {
    return (
      <section
        className={this.props.id % 2 ? 'bg-white' : 'bg-white-grey-gradient'}
      >
        <MDBContainer>
          <MDBRow className="mb-lg-5">
            <MDBCol lg="7" sm="12" className="mb-4">
              <div className="storage-content pr-0 pr-lg-3">
                <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
                  {this.props.platform.title}
                </h3>
                <p className="font-w-400 text-medium">{this.props.platform.description}</p>
              </div>
            </MDBCol>

            <MDBCol lg="5" sm="12" className="align-self-center">
              <div className="storage-content mb-5 mb-lg-0">
                <Img
                  fluid={this.props.platform.image.childImageSharp.fluid}
                  className="mx-auto d-block mt-4 mt-lg-0"
                  alt={this.props.platform.imageAltText}
                />
              </div>
            </MDBCol>
          </MDBRow>

          <MDBRow center>
            {this.props.platform.sectionproduct.map((products, index) => {
              return (
                <MDBCol md="12" lg="6" className="d-flex" key={index}>
                  <div className="storage-content bg-white rounded innovation-box p-3 my-3 flex-fill">
                    <div
                      className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2"
                      dangerouslySetInnerHTML={{ __html: products.subtitle }}
                    />
                    <div
                      className="mt-3 font-w-400 text-medium"
                      dangerouslySetInnerHTML={{ __html: products.text }}
                    />
                  </div>
                </MDBCol>
              )
            })}
          </MDBRow>

          <MDBRow>
            <MDBCol lg="12">
              <div className="btn-toolbar pt-40 justify-content-sm-center">
                {this.props.platform.btnlink &&

                  <Link
                    to={this.props.platform.btnlink}
                    className="nav-link btn btn-mdb-color btn-sm-block"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                    {this.props.platform.btntext}
                  </Link>
                }

                {this.props.platform.document &&
                  <a
                    href={this.props.platform.document.publicURL}
                    className="nav-link btn btn-mdb-color btn-sm-block"
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    {this.props.platform.doctext}
                  </a>
                }
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    )
  }
}
export default StorageProducts
